(function () {
  'use strict';

  class ModuleSetEditCtrl {
    constructor($stateParams, ModuleSet, $mdToast) {
      let vm = this;
      vm.$stateParams = $stateParams;
      vm.ModuleSet = ModuleSet;
      vm.$mdToast = $mdToast;
      vm.init();
    }
    init() {
      let vm = this;
      vm.ModuleSet.myModuleSetGet(vm.$stateParams)
        .then((data)=> {
          vm.module_set = data.module_set;
        });
    }
    addCategory() {
      const vm = this;
      vm.module_set.module_categories.push({
        field_type: 'text'
      });
    }
    removeItem(module_categories, index) {
      module_categories.splice(index, 1);
    }
    submitModuleSet() {
      let vm = this;
      vm.ModuleSet.myModuleSetUpdate(vm.module_set)
        .then(()=> {
          vm.message('Module Set updated.');
        });
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name learning.moduleSetEdit.controller:ModuleSetEditCtrl
   *
   * @description
   *
   */
  angular
    .module('learning.moduleSetEdit')
    .controller('ModuleSetEditCtrl', ModuleSetEditCtrl);
}());
